<template>
  <div>
    <el-card>
        <div slot="header">

        </div>
        <div>
             <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item>
                  <el-date-picker value-format="yyyy-MM-dd" @change="getList"  @input="$forceUpdate()"
                      v-model="selectStartDate" type="daterange" ange-separator="至"  start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getList" icon="el-icon-search">查询</el-button>
                </el-form-item>
              </el-form>
        <el-table :data="dataList" stripe table-layout='auto' class="tableAuto">
          <el-table-column label="#" type="index"></el-table-column>
          <el-table-column :width="flexWidth('DeliveryName',dataList,'',30)" label="发货单位" prop="DeliveryName"></el-table-column>
          <el-table-column :width="flexWidth('DeliveryAddress',dataList,'',30)" label="起始地" prop="DeliveryAddress"></el-table-column>
          <el-table-column :width="flexWidth('ConsigneeName',dataList,'',70)" label="收货单位" prop="ConsigneeName"></el-table-column>
          <el-table-column :width="flexWidth('ConsigneeAddress',dataList,'',20)" label="目的地" prop="ConsigneeAddress"></el-table-column>
          <el-table-column :width="flexWidth('ItemUnit',dataList,'',50)" label="单位" prop="ItemUnit"></el-table-column>
          <el-table-column :width="flexWidth('ItemCount',dataList,'',50)" label="数量" prop="ItemCount"></el-table-column>
          <el-table-column :width="flexWidth('ItemTotalPrice',dataList,'',50)" label="小计" prop="ItemTotalPrice"></el-table-column>
        </el-table>
         <div class="footer">合计：{{ totalPrice }}</div>
        </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    name:'',
    data () {
        return{
            selectStartDate:'',
            userNewsId:'',
            supplierNewsId:'',
            roleName:'',
            dataList:[],
            totalPrice: 0,
        }
   },
   created(){
    this.userNewsId =  window.sessionStorage.getItem("userNewsId");
    this.supplierNewsId =  window.sessionStorage.getItem("supplierNewsId");
    this.roleName =  window.sessionStorage.getItem("roleName");
    this.getTestTime();
},
   methods:{
    async getList() {
      let that = this;
        let data= {
          UserNewsId: that.userNewsId,
          SupplierNewsId: that.supplierNewsId,
          RoleName: that.roleName,
          BeginDate: that.selectStartDate[0],
          EndDate: that.selectStartDate[1]
        }
		await that.$http
          .post('Report/GetConfigneeData',data)
					.then(
          (res) => {
            //console.log(res)
            if (res.data.Code !== "200") return that.$message.error(res.data.Message);
            that.dataList.length = 0;
              this.dataList= res.data.Obj
              that.totalPrice = 0;
				res.data.Obj.forEach((item, index) => {
					that.totalPrice += item.ItemTotalPrice;
				})
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
					
	},
    getTestTime () {
        //console.log('111')
        let isDate = new Date()
        let sTime = `${isDate.getFullYear()}-${isDate.getMonth() }-${isDate.getDate()}`
        let eTime = `${isDate.getFullYear()}-${isDate.getMonth() + 1}-${isDate.getDate()}`
        sTime = `${sTime} 00:00:00`
        eTime = `${eTime} 23:59:59`
        this.selectStartDate = [new Date(sTime), new Date(eTime)] // 显示的默认时间
        console.log(this.selectStartDate)
      },
      flexWidth(prop, tableData, title, num = 0) {
      if (tableData.length === 0) {
        //表格没数据不做处理
        return;
      }
      let flexWidth = 0; //初始化表格列宽

      flexWidth = this.$common.flexWidth(prop, tableData, title, num);
      return flexWidth + "px";
    },
   },
   
  }
</script>

<style scoped>
   .footer{
    margin: right;
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
</style>